<template>
  <div class="mobile">
    <div class="header">
      <img width="100%" src="../../assets/mobileCar/header.png" alt="" />
    </div>
    <div class="banner">
      <img width="100%" src="../../assets/mobileCar/banner.png" alt="" />
    </div>
    <ul class="content">
      <li>
        <h1>{{ this.$t("car.math") }}</h1>
      </li>
      <li>
        <h1>
          <span>{{ this.$t("car.adas") }}</span
          ><br /><span>{{ this.$t("car.safe") }}</span>
        </h1>
      </li>
    </ul>
    <div class="interior">
      <h1>{{ this.$t("car.navigation") }}</h1>
    </div>
    <div class="footer">
      <h1>{{ this.$t("car.system") }}</h1>
      <img width="100%" src="../../assets/mobileCar/footer.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {
    // console.log(this.$parent)
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.header {
  img {
    vertical-align: bottom;
  }
}
.banner {
  img {
    vertical-align: bottom;
  }
}
.content {
  padding: 0 4.2667vw;
  overflow: hidden;
  margin-bottom: 3.2vw;
  li {
    width: 100%;
    height: 45.8667vw;
    background-size: cover !important;
    margin-top: 3.2vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  li:first-child {
    background: url("../../assets/mobileCar/content-one.png") no-repeat center;
    h1 {
      width: 100%;
      text-align: right;
      font-size: 4.2667vw;
      font-family: 'Meiryooo';
      font-weight: bold;
      line-height: 5.8667vw;
      color: #000000;
    }
  }
  li:last-child {
    background: url("../../assets/mobileCar/content-two.png") no-repeat center;
    h1 {
      margin-left: 3.4667vw;
      span {
        font-size: 4.2667vw;
        font-family: 'Meiryooo';
        font-weight: bold;
        line-height: 5.8667vw;
        color: #000000;
      }
    }
  }
}
.interior {
  width: 100vw;
  height: 50.1333vw;
  background: url("../../assets/mobileCar/interior.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 3.7333vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 5.3333vw;
    color: #ffffff;
  }
}
.footer {
  overflow: hidden;
  h1 {
    margin-top: 8vw;
    font-size: 3.7333vw;
    font-family: 'Meiryooo';
    font-weight: bold;
    line-height: 5.3333vw;
    text-align: center;
    color: #000000;
  }
}
</style>
