<template>
  <div class="box">
    <div class="header">
      <img width="100%" src="@/assets/car/header@2x.png" alt="" />
    </div>
    <div class="banner">
      <div class="container banner-info">
        <!-- <p>{{ this.$t("car.FullScreen") }}</p>
            <p>{{this.$t('car.FullScreen')}}</p> -->
      </div>
    </div>
    <div class="content container">
      <ul class="intro">
        <li>
          <p>{{ this.$t("car.math") }}</p>
        </li>
        <li>
          <p>{{ this.$t("car.adas") }}<br />{{ this.$t("car.safe") }}</p>
        </li>
        <li>
          <p>{{ this.$t("car.navigation") }}</p>
        </li>
      </ul>
      <div class="sponsor">
        <p>{{ this.$t("car.system") }}</p>
        <img width="100%" src="@/assets/car/sponsor@2x.png" alt="" />
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
    
<style lang="less">
.box {
  .header {
    background-color: rgb(242, 242, 242);
    img {
      vertical-align: bottom;
    }
  }
  .banner {
    background: url("../../assets/car/banner@2x.png") no-repeat center;
    background-size: cover;
    .banner-info {
      height: 790px;
      display: flex;
      justify-content: space-between;
      p {
        margin-top: 100px;
        font-size: 50px;
        font-family:'Meiryooo';
        font-weight: bold;
        line-height: 66px;
        color: #ffffff;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .intro {
      margin-top: 40px;
      li {
        background-size: cover !important;
        height: 600px;
        margin-bottom: 80px;
        display: flex;
        align-items: center;
        p {
          font-size: 50px;
          font-family:'Meiryooo';
          font-weight: bold;
          line-height: 66px;
          color: #000;
        }
      }
      li:first-child {
        background: url("../../assets/car/1@2x.png") no-repeat center;
        justify-content: flex-end;
        p {
          margin-right: 60px;
        }
      }
      li:nth-child(2) {
        background: url("../../assets/car/2@2x.png") no-repeat center;
        justify-content: flex-start;
        p {
          margin-left: 80px;
        }
      }
      li:last-child {
        background: url("../../assets/car/3@2x.png") no-repeat center;
        justify-content: center;
        p {
          color: #ffffff;
        }
      }
    }
    .sponsor {
      margin-bottom: 60px;
      p {
        text-align: center;
        font-size: 50px;
        font-family:'Meiryooo';
        font-weight: bold;
        line-height: 66px;
        color: #000000;
        margin-bottom: 40px;
      }
      img {
        vertical-align: bottom;
      }
    }
  }
}
</style>